import { useEffect } from "react";

export default function MarkerLayer(props) {
  const { data, map, layer } = props;
  const { id, defaultChecked, icon } = layer
  useEffect(() => {
    map.current.on("load", function () {
      map.current.loadImage(
        './marker/' + icon,
        function (error, image) {
          console.log(image)
          if (error) throw error;
          if (!map.current.hasImage(id + "-marker"))
            map.current.addImage(id + "-marker", image, {
              width: "30px",
              height: "41px",
            });
        }
      );
      map.current.addSource(id, {
        type: "geojson",
        data: data,
      });
      map.current.addLayer({
        id: id,
        type: "symbol",
        source: id,
        layout: {
          "icon-image": id + "-marker",
          "icon-anchor": "bottom",
          "icon-size": 0.5,
          'icon-overlap': 'always',
          "visibility": defaultChecked ? 'visible' : 'none',
          "symbol-sort-key": 100
        },
      });
    });
  });

  return null;
}
